import * as Sentry from "@sentry/astro";

Sentry.init({
  dsn: "https://0adfd9d9afbbc710d0a131c87a0c1692@o4508786608570368.ingest.de.sentry.io/4508786755895376",
  debug: false,
  environment: import.meta.env.PUBLIC_VERCEL_ENV,
  release: import.meta.env.PUBLIC_VERCEL_GIT_COMMIT_SHA,
  tracesSampleRate: 1,
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1,
});